

.row .vendor-summary-table {
  padding: 0;
}

.vendor-summary {
  border-style: solid;
  border-color: grey;
  cursor: pointer;  
}

.vendor-summary.top-half {
  margin-top: 1em;
  border-width: 1px 1px 0 1px;
  border-top-left-radius:  4px;
  border-top-right-radius: 4px;
}

.vendor-summary.bottom-half {
  margin-bottom: 1em;
  border-width: 0 1px 1px 1px;
  border-bottom-left-radius:  4px;
  border-bottom-right-radius: 4px;
}

.vendor-summary .vendor-name {
  font-size: 120%;
  font-weight: bolder;
}

// Not currently used
.vendor-summary-table .sort-header {
  cursor: pointer;
  font-size: bigger;
}