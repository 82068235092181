
.vendor-tab .table-header {
  cursor: pointer;
  position: relative;
  padding-right: 1.5em;
  
  &:after {
    position: absolute;
    right: 0;
    font-family: 'Glyphicons Halflings';
    content: "\e150";
    opacity: 0.2;
  }

  &.sorted-forward:after {
    content: "\e155";
    opacity: 0.8;
  }
  &.sorted-reverse:after {
    content: "\e156";
    opacity: 0.8;
  }

}

.vendor-tab {
  // Hack because Bootstrap is overriding typeahead 
  .rbt-input-multi {
    height: auto;
  }

  .inventory-target {
    &.focused {
      --box-shadow-color: gray;
      box-shadow: 0px 0px 1em var(--box-shadow-color);
    }

    &.panel-warning {
      --box-shadow-color: #faebcc;
    }

    &.panel-success {
      --box-shadow-color: #d6e9c6;
    }

    &.panel-danger {
      --box-shadow-color: #ebccd1;
    }
  }
}